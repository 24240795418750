// Components that need to be available in the Rails templates
import { defineAsyncComponent } from "vue";

const ConfirmationInstructionsForm = defineAsyncComponent(() => import("~/components/navigation/confirmation_instructions_form.vue"));
const ComparisonIndex = defineAsyncComponent(() => import("~/components/comparison/index.vue"));
const EditPasswordForm = defineAsyncComponent(() => import("~/components/navigation/edit_password_form.vue"));
const ExpandableSideMenu = defineAsyncComponent(() => import("~/components/navigation/expandable_side_menu.vue"));
const FixturesHeatmap = defineAsyncComponent(() => import("~/components/fixtures/heatmap.vue"));
const InfoText = defineAsyncComponent(() => import("~/components/navigation/info_text.vue"));
const LoginForm = defineAsyncComponent(() => import("~/components/navigation/login_form.vue"));
const NavSelector = defineAsyncComponent(() => import("~/components/navigation/nav_selector.vue"));
const NewUserForm = defineAsyncComponent(() => import("~/components/navigation/new_user_form.vue"));
const PlayerComparison = defineAsyncComponent(() => import("~/components/comparison/comparison.vue"));
const PlayersEdit = defineAsyncComponent(() => import("~/components/players/edit.vue"));
const PlayersIndex = defineAsyncComponent(() => import("~/components/players/index.vue"));
const PlayersNew = defineAsyncComponent(() => import("~/components/players/new.vue"));
const PlayersShow = defineAsyncComponent(() => import("~/components/players/show.vue"));
const ResetFilterChip = defineAsyncComponent(() => import("~/components/navigation/reset_filter_chip.vue"));
const ResetPasswordForm = defineAsyncComponent(() => import("~/components/navigation/reset_password_form.vue"));
const TeamOptimizerIndex = defineAsyncComponent(() => import("~/components/team_optimizer/index.vue"));
const TeamsIndex = defineAsyncComponent(() => import("~/components/teams/index.vue"));
const TeamShow = defineAsyncComponent(() => import("~/components/teams/show.vue"));
const ToastComponent = defineAsyncComponent(() => import("~/components/navigation/toast_component.vue"));
const UnlockUserForm = defineAsyncComponent(() => import("~/components/navigation/unlock_user_form.vue"));
const UsersIndex = defineAsyncComponent(() => import("~/components/users/index.vue"));
const UserAccount = defineAsyncComponent(() => import("~/components/users/account.vue"));

export default {
    ConfirmationInstructionsForm,
    ComparisonIndex,
    EditPasswordForm,
    ExpandableSideMenu,
    FixturesHeatmap,
    InfoText,
    LoginForm,
    NavSelector,
    NewUserForm,
    PlayerComparison,
    PlayersEdit,
    PlayersIndex,
    PlayersNew,
    PlayersShow,
    ResetFilterChip,
    ResetPasswordForm,
    TeamOptimizerIndex,
    TeamsIndex,
    TeamShow,
    ToastComponent,
    UnlockUserForm,
    UsersIndex,
    UserAccount,
}
